const Podata = [
        
    {
      nodejs:{
       'title': 'Working on Nodejs',
       'description':'Nodejs allows us to build a sigle thread web applications with highly scalable  architecture. I have used this technology on API generation for React and multiple task based applications.',
       'link':'https://prozko.glitch.me/', 
      },
    
      php:{
        'title': 'PHP working stream', 
        'description':'PHP is a serverside programme and is most popular in the world. I have done multiple projects on this programme. Generally, i have worked on Laravel, Codeigniter and Core.',
        'link':'http://app.freecluster.eu/', 
      },
      python:{
        'title': 'Workstream on Python',
        'description':'On the top of building application on this programme, i have reached to webframework like Django, Flask, Pyramid.',
        'link':'#', 
     }, 

      rails:{
        'title': 'Ruby On Rails Apps',
        'description':'Ruby on Rails allows us a super fast working environment to setup the CRUD functionality in order to manage the CMS. I have build a blog application on it within a day.',
        'link':'#', 
     },

    }


];


export default Podata;




