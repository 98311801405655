import React from "react";

export default ()=>{

return (


<div className="content-wrapper home">


<h1>Hi,</h1>
<h3>This is Prakash</h3>
<p>A Developer expertised by <strong>PHP</strong>, <strong>Python</strong>, <strong>Ruby</strong>, <strong>Nodejs</strong></p>

</div>


);


}
